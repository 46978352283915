import { useEffect, useState } from "react";
import { FacebookPixel } from "react-use-facebook-pixel";

// to prevent pixel reinitialization on every rerender
let facebookPixelSingleton: FacebookPixel | null = null;

export const useMetaPixel = () => {
  const [pixelID, setPixelID] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!facebookPixelSingleton && pixelID) {
      const initializeFacebookPixel = () => {
        const pixel = new FacebookPixel({
          pixelID,
          debug: false,
        });

        pixel.init({});
        pixel.trackEvent("PageView");

        facebookPixelSingleton = pixel;
      };

      initializeFacebookPixel();
    }
  }, [pixelID]);

  return { pixel: facebookPixelSingleton, initPixel: setPixelID };
};
